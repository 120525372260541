import React, { useState, useEffect } from "react";
import "./RestaurantList.css";


const RestaurantList = (props) => {

    const [loading, setLoading] = useState(true);
    const[restaurantData,setRestaurantData] = useState([]);

    const myHeaders = new Headers();

    myHeaders.append("Accept", "text/plain");
  
    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
  
    console.log("calling  get restaruant list");
  
    useEffect(() => {
        fetch("https://loyalty.api.kiwijones.com/Subscription/ListResturantsInSubscription/" + props.subscription +"", requestOptions)


      .then((response) => response.text())
      .then((result) => {
       
        console.log(props)

        setRestaurantData(JSON.parse(result));
  
        console.log(result)

        setLoading(false)
      
      })
      .catch((error) => console.error(error))
    }, [props.subscription]);

  return (
    <>
     <hr></hr>    
    {loading ? <img src="../Infinity.svg" /> : ""}  
    {/* <p> UserId: {props.userId}</p>
    <p> subscription: {props.subscription}</p> */}
    Restaurants

    <div>
      <table className="table">
        <tr>
          <th>Name</th>
          <th>PhoneNo</th>
         
        </tr>

        {restaurantData.map((item) => (
          <tr>
            <td>{item.name}</td>
            <td>{item.phoneNo}</td>
          </tr>
        ))}
      </table>

      <hr></hr>
    </div>
  </>
  )
}

export default RestaurantList