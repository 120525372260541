import { useEffect } from "react";
import React, { useState } from "react";
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';
import { useGlobalState } from "./GlobalStateContext";
import './Points.css'
import RestaurantList from "./RestaurantList";
import Transactions from "./Transactions";
//import { TailSpin } from "react-loader-spinner";
import { Navigate } from 'react-router-dom';

const Points = () => {

  const [loading, setLoading] = useState(false);
  const { userGuid, setUserGuid } = useGlobalState();
 
  const { apiUrl, setApiUrl } = useGlobalState();
  const [userPoints, setUserPoints] = useState(0);

  const [data, setData] = useState([]);
  const [transactions, setTransactions] = useState(false);
  const [restaurantList, setRestaurantList] = useState(false);
  const [subscription, setSubscription ] = useState('');
  const [logout, setLogout] = useState(false) ;
  

  //console.log(apiUrl);

  const myHeaders = new Headers();
  myHeaders.append("Accept", "text/plain");

  const requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };

  const handleClick = (e) => {

    setLoading(true)
    setTransactions(false)
    setRestaurantList(false)

    console.log(e)
   
    setSubscription(e)

    // shpw the transactions on page  
    setTransactions(true)

    setRestaurantList(true)
};

  function onLogout()
  {

    setLogout(true);

  }
  //   useEffect(() => {

  //   
  //   },[])

  // }

  useEffect(() => {


    console.log("userGuid: " + userGuid)

    fetch(
      "https://loyalty.api.kiwijones.com/user/FindPoints/" + userGuid,
      requestOptions
    )
      .then((response) => response.text())
      .then((result) => {

        setData(JSON.parse(result));

        console.log(result);

        console.log('-----------------------')
        setUserPoints(data[0]);
        console.log('-----------------------')
        


        setLoading(false)


        //console.log(userPoints);
      })
      .catch((error) => console.error(error));
  }, []);




  return (
    <>
      {logout ? (
        <Navigate to="/" />


       ):('')
      }

      {/* {userGuid} */}
      <br />
    
    
      <div>
        <h3>Your points</h3>

        <button className='primary-button' type="submit"  onClick={onLogout}>Logout</button>

        <br/>

           <table className="table">
          <tr>
            <th>Location</th>
            <th>Points</th>
          
          </tr>

          {data.map((item) => (
            <tr>
              <td> <a href="#" onClick={() => handleClick(item.subscriptionId)}>{item.site}</a></td>
            
              <td>{item.points}</td>
            </tr>
          ))}
        </table>

      </div>
     

      {transactions ? (<Transactions userId={userGuid} subscription={subscription}/>):(null)}


      {restaurantList ? (<RestaurantList subscription={subscription}/>):(null)}

    </>
  );
};

export default Points;
