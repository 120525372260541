import React, {useState} from "react";


const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };


const NoEmail = ({ handleDataNoEmail },props) => {



    console.log(props.userId)

    const [email, setEmail] = useState('');

    const [enableEmailSubmit, setEnableEmailSubmit] = useState(false)

    const [isValidEmail, setIsValidEmail] = useState(true);

    const handleEmailChange = () =>{
       
        // todo set client email
        
        // htis is being pushed back to the parent
        handleDataNoEmail(email);
    }

    const handleEmailInputChange = (event) => {

        setEnableEmailSubmit(false)
        const inputValue = event.target.value;
        setEmail(inputValue);
        setIsValidEmail(validateEmail(inputValue));
        setEnableEmailSubmit(true)

      };


  return (
    <>
      <div>
        we need an email address to reset password
        <br />
        <input
          style={{ margin: "3px" }}
          className="custom-input"
          type="text"
          id="email"
          value={email}
          placeholder="email address"
          onChange={handleEmailInputChange}
          required
        />
        <br />
        {!isValidEmail && (
          <span style={{ color: "red" }}>Invalid email address</span>
        )}
        <br />
        <button
          className="primary-button"
          style={{ margin: "3px" }}
          type="submit"
          disabled={!enableEmailSubmit}
          onClick={handleEmailChange}
        >
          Set email
        </button>
      </div>
    </>
  );
};

export default NoEmail;
