import { useEffect } from "react";
import React, { useState } from "react";
import NoEmail from "./Forgot/NoEmail";
import {  toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Navigate, Link } from 'react-router-dom';

const Forgot = () => {


  const notify = () => toast("Wow so easy!");

  function isNumber(value) {
    return typeof value === "number";
  }

  const [userId, setUserId] = useState("0");
  const [code, setCode] = useState("0000");
  const [newCode, setNewCode] = useState(false);
  const [navAway, setNavAway] = useState(false);
  const [phoneNumberInput, setPhoneNumberInput] = useState(true);
  const [codeNumberInput, setCodeNumberInput] = useState(false);
  const [isVisible, setIsVisible] = useState(false);

  //const [isValidEmail, setIsValidEmail] = useState(true);

  const [hasNoEmail, setHasNoEmail] = useState(false);
  const [password1, setPassword1] = useState("");
  // const [password2, setPassword2] = useState('')
  const [dataFromNoEmail, setDataFromNoEmail] = useState("");

  const CheckCode = (event) => {
    // console.log(event);
    // console.log(code);
  
    if (event == code) {

      //console.log('hide code')
      
      setCodeNumberInput(true);
      setIsVisible(true)
    }

    if(event.length > 4){


        toast.warning("Code incorrect")
    }

  };

  const CheckPassword1 = (event) => {
    // console.log("check password1");
    // console.log(event);
    setPassword1(event);

  };

  const CheckPassword2 = (event) => {

    // the purpose of this is to check two passwords
    // if there is a match then the password will aotomatically be updated 
    // and the user reditected to the login screen
    // console.log("check password2");
    // console.log(event);
    // console.log(password1);

    if (event == password1) {
      //console.log("its a match");

      //console.log(userId);

      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      const raw = JSON.stringify({
        id: userId,
        password: event,
      });

      const requestOptions = {
        method: "PUT",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(
        "https://loyalty.api.kiwijones.com/user/SetPassword",
        requestOptions
      )
        .then((response) => response.text())
        .then((result) => {
         
          toast.success("Password reset OK")

          setNavAway(true); // this will trigger the redirect to the root ... login 
          setCodeNumberInput(false);
        })
        .catch((error) => console.error(error));
    }
  };


  function HandleResetPassword() {


    setLoading(true);



    console.log("HandleResetPassword: " + username);


    if(username.length < 1){

        toast.warn("Invalid")
        setNavAway(true);  // return to login page

    }

    const myHeaders = new Headers();
    myHeaders.append("Accept", "text/plain");

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };


    // search for user in the database
    let url =
      "https://loyalty.api.kiwijones.com/user/GetUserByPhoneNo/" +
      username +
      " ";

    console.log(url);

    var dd = "";

    //useEffect(() => {

    fetch(url, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        //console.log("---  user logged in ---");

        //console.log(result)


        let user = JSON.parse(result);

        if (user._id == null || user._id.length == 0 ) {

            toast.warn("Invalid")

            setNavAway(true);  // user not found nav to login

        }


        //console.log(user);

        //dd = user._id;

        setUserId(user._id);

        // setUserId((prev) => {
        //   setUserId(prev);

        //   console.log(prev);
        //   return prev;
        // });

        // console.log("code: " + dd);

        // console.log(userId);

        // console.log("---------------------------------------------");

        if (user.email == null || user.email.length < 4) {
          //console.log("No Email");

          SetNoEmail(user._id);
        } else {
          SendCode_ViaEmail(user._id);

          //console.log("Send Code");
        }

        //console.log(user);
        // setAuth(true)
        // setUserGuid(result)
        setLoading(false);
      })
      .catch((error) => console.error(error));
  }

  function SetNoEmail(e) {
    console.log(e);

    setUserId(e);
    setHasNoEmail(true);
  }

  function SendCode_ViaEmail(userId) {
    console.log("SendCode_ViaEmail: " + userId);

    const requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    let prevId = "";

    // setUserId(prev => {
    //           prevId = prev
    //           console.log('prevId: ' + prevId)
    //     })

    //     console.log('prevId: ' + prevId)

    fetch(
      "https://loyalty.api.kiwijones.com/user/SendPasswordCode/" + userId,
      requestOptions
    )
      .then((response) => response.text())
      .then((result) => {
        console.log(result);

        if (isNumber(Number(result))) {
          setCode(result);

          setUserId(userId);

          setPhoneNumberInput(false);

          console.log("Code is correct");

          setIsVisible(false)
        }
      })
      .catch((error) => console.error(error));
  } //function SendCode_ViaEmail(userId) {


  function EmailNotReceived(){

    setIsVisible(true)
    SetNoEmail(userId);

  }

  // this handles the updating of the user that didn't have an email address
  // called after the new email address has been entered
  function handleDataNoEmail(newEmailAddress) {

    // 
    console.log("handleDataNoEmail: " + newEmailAddress);

    setHasNoEmail(newEmailAddress);

    setDataFromNoEmail(true);

    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    var url =
      "https://loyalty.api.kiwijones.com/user/UpdateEmail/" +
      userId +
      "/" +
      newEmailAddress +
      "";

    console.log(url);

    fetch(url, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        setHasNoEmail(false); // this hides the email reauest field

        SendCode_ViaEmail(userId);

        //console.log(result);
      })
      .catch((error) => console.error(error));
  } //function handleDataNoEmail(data) {

  const [username, setUsername] = useState("");
  const [loading, setLoading] = useState(false);

  return (
    <>
   
      {navAway ? (
        <Navigate to="/" />
      ) : (
        <div>
          {loading ? <img src="../Infinity.svg" /> : ""}

          {hasNoEmail ? <NoEmail handleDataNoEmail={handleDataNoEmail} /> : ""}

          {phoneNumberInput ? (
            <div>
               
              <br />

              <input
                style={{ margin: "3px" }}
                className="custom-input"
                type="number"
                id="phoneNo"
                value={username}
                placeholder="Phone No"
                onChange={(e) => setUsername(e.target.value)}
                required
              />
              <br />

              <button
                className="primary-button"
                style={{ margin: "3px" }}
                type="submit"
                onClick={HandleResetPassword}
              >
                Reset Password
              </button>
              <br/>
              <Link to="/">
                        <button className='btn btn-link'>Cancel</button>
              </Link>
            </div> //phoneNumberInput
          ) : (
            
            <div className={isVisible ? 'hidden' : ''} >
                 A verification code has been emailed to you
                 <br/>
              <input
                style={{ margin: "3px" }}
                className="custom-input"
                type="number"
                id="newCode"
                //value={newCode}
                placeholder="Code"
                onChange={(e) => CheckCode(e.target.value)}
                required
              />

              <br/>

              
                        <button className='btn btn-link' onClick={EmailNotReceived}>Email not received</button>
              
              <br/>

              <Link to="/">
                        <button className='btn btn-link'>Cancel</button>
              </Link>
            </div>
            
          )}

          {codeNumberInput ? (
            <div>
              <h3>set new password</h3>
              <input
                style={{ margin: "3px" }}
                className="custom-input"
                type="password"
                id="password1"
                //value={password1}
                placeholder="Password"
                onChange={(e) => CheckPassword1(e.target.value)}
                required
              />
              <br />
              <input
                style={{ margin: "3px" }}
                className="custom-input"
                type="password"
                id="password2"
                //value={password2}
                placeholder="Retype passowrd"
                onChange={(e) => CheckPassword2(e.target.value)}
                required
              />
              <br />

              <button
                className="primary-button"
                style={{ margin: "3px" }}
                type="submit"
                onClick={notify}
              >
                Set password
              </button>
            </div>
          ) : (
            ""
          )}
        </div>
      )}
    </>
  );
};

export default Forgot;
