import React, { useState, useEffect } from "react";
import "./Points.css";

const Transactions = (props) => {
  const [loading, setLoading] = useState(true);
  console.log("load transactions");
  const [data, setData] = useState([]);

  const myHeaders = new Headers();

  myHeaders.append("Accept", "text/plain");

  const requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };

  console.log("calling db");

  useEffect(() => {
    fetch(
      "https://loyalty.api.kiwijones.com/user/ListTransactions/" +
        props.subscription +
        "/" +
        props.userId +
        "",

      requestOptions
    )
      .then((response) => response.text())
      .then((result) => {


        console.log('-- Transaction Results  --');


        console.log(result);

        setData(JSON.parse(result));

        setLoading(false)

      })
      .catch((error) => console.error(error));
  }, [props.subscription]);

 
  return (
    <>
       <hr></hr>    
      {loading ? <img src="../Infinity.svg" /> : ""}  

    
      Recent transactions
      
      <div>
        <table className="table">
          <tr>
            <th>Date</th>
            <th>Location</th>
            <th>Amount</th>
            <th>Points</th>
          </tr>

          {data.map((item) => (
            <tr>
              <td>{item.date}</td>
              <td>{item.restaurant}</td>
              <td>{item.amount}</td>
              <td>{item.points}</td>
            </tr>
          ))}
        </table>
      </div>
    </>
  );
};

export default Transactions;
