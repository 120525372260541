import React, { createContext, useContext, useState } from 'react';

// Create the context
const GlobalStateContext = createContext();

// Create a provider component
export const GlobalStateProvider = ({ children }) => {
    
  const [userGuid, setUserGuid] = useState('');
  //const [apiUrl, setApiUrl] = useState("http://rm.kiwijones.com:5003");
  

  return (
    <GlobalStateContext.Provider value={{ userGuid, setUserGuid }}>
      {children}
    </GlobalStateContext.Provider>
  );
};

// Custom hook to consume the context
export const useGlobalState = () => useContext(GlobalStateContext);