import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useGlobalState } from './GlobalStateContext'
import {  toast } from 'react-toastify';
import { Navigate } from 'react-router-dom';

function LoginForm() {

  const { userGuid, setUserGuid } = useGlobalState();

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const [navAway, setNavAway] = useState(false);

  const [auth,setAuth] = useState(false)
  const [loading, setLoading] = useState(false);
  
  const myHeaders = new Headers();
  myHeaders.append("Accept", "text/plain");

  const requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow"
  };

  const handleSubmit = (event) => {


    event.preventDefault();

    setLoading(true)
    
    // Here you can perform authentication logic
    console.log('Username:', username);
    console.log('Password:', password);

    // Reset form fields
    setUsername('');
    setPassword('');
   
    setUserGuid('hello')


    let url = "https://loyalty.api.kiwijones.com/user/Login/"+ username +"/"+ password +""
    console.log(url)

   fetch(url, requestOptions)
  .then((response) => response.text())
  .then((result) => {

    // console.log("---  user logged in ---")

    // console.log(result) 

    if(result.length < 5){

      toast.warn("invalid login")

      setNavAway(true)

    }
    else{

      toast.success("Login OK")

      setAuth(true)
    
      setUserGuid(result)
     
    }

    setLoading(false)

  })
  .catch((error) => {
    
    console.error(error)

    toast.warning(error)
  
  });

  };

  return (

      <>

      {navAway ? <Navigate to="/" /> :""}

      {loading ? <img src="../Infinity.svg" /> : ""}

      {auth ? (
        <Navigate to="/points" />
      ) : (

        <form onSubmit={handleSubmit}>
        <div>
          <h3>RM Loyalty</h3>
          {/* <label htmlFor="username">Username:</label> */}
          <input
            style={{ margin: '3px' }}
           className='custom-input'
            type="number"
            id="username"
            value={username}
            placeholder='Phone Number'
            onChange={(e) => setUsername(e.target.value)}
            required
          />
        </div>
        <div>
          {/* <label htmlFor="password">Password:</label> */}
          <input
            style={{ margin: '3px' }}
            className='custom-input'
            type="password"
            id="password"
            value={password}
            placeholder='Password'
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>
        <button className='primary-button' 
        style={{ margin: '3px' }}
        type="submit">Login</button>
  
        <div>
  
        <Link to="/forgot">
          <button className='secondary-button' style={{ margin: '3px' }}>Reset password</button>
        </Link>
  
        </div>
  
       
  
        <div>
  
        {/* <Link to="/contact" style={{ textDecoration: 'none', color: 'white' }}>
          <button style={{ backgroundColor: 'blue', padding: '10px', border: 'none' }}>Go to Contact Page</button>
        </Link> */}
        </div>
  
      </form>
        
        
      )}

      
      
      </>

  );
}

export default LoginForm;