import logo from './logo.svg';
import React, { useState, useEffect } from "react";
import Points from './Pages/Points';
import {  BrowserRouter as Router, Route, useLocation, Routes, Link} from 'react-router-dom';
import { GlobalStateProvider } from './Pages/GlobalStateContext'
import Login from './Pages/Login';
import Forgot from './Pages/Forgot';
import { ToastContainer } from 'react-toastify';

import './App.css';

function App() {
  return (
    <GlobalStateProvider>
    <div className="App">
      <header className="App-header">

       <Router>
      
        <Routes>
          {/* <Route exact path="/" element={<LoginForm/>} /> */}
          <Route exact path="/" element={<Login/>} />
           <Route path="/forgot" element={<Forgot/>} />
          <Route path="/Points" element={<Points/>} />
        </Routes>
       
      </Router>

      <ToastContainer

       position="top-right"
       autoClose={2000}
       hideProgressBar
       newestOnTop={false}
       closeOnClick
       rtl={false}
       pauseOnFocusLoss
       draggable
       pauseOnHover
       theme="light"
       
/>

      </header>


    </div>

    </GlobalStateProvider>
  );
}

export default App;
